<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-3">
            <CCol md="12" class="p-0">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
                    <h2 class="text-center text-white middle-center"><b>FAQ</b></h2>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h4 class="my-0 text-center"><b>{{pertanyaan_faq}}</b></h4>
                        </CCardHeader>
                        <CCardBody>
                            <div v-html="jawaban_faq" style="width: 100%;" class="child_img_100"></div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
    const null_array = [];
    export default {
        name: "PusatBantuan",
        data() {
            return {
                
                pertanyaan_faq: '',
                jawaban_faq: '',
                idFaq: this.$route.params.idFaq,
            };
        },
        methods: {
        },
        created() {
            axios.get(this.apiLink + "api/crud/faq", {
                params: {
                    id: this.idFaq,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_faq = response.data;
                var res_faq_data = res_faq.data[0].data[0];
                if (res_faq.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_faq.data.message
                    });
                }
                else{
                    this.pertanyaan_faq = res_faq_data.faq_pertanyaan;
                    this.jawaban_faq = res_faq_data.faq_jawaban;
                    
                }
            });
        },
    };
</script>